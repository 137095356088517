import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, RouteComponentProps} from "react-router-dom";
import Cookies from 'universal-cookie';
import {parse as parseQuery} from 'query-string';

const App: React.FC = () => {
    return (
        <Router>
            <Route exact path="/" component={index}/>
            <Route path="/mobile-login/:deviceID" component={MobileLoginScreen}/>
            <Route path="/mobile-login-redirect/" component={MobileLoggedScreen}/>
        </Router>
    );
};

function index() {
    return (
        <div className="App">
            <h1>There is nothing interesting here (yet)</h1>
        </div>
    );
}

type MobileLoginParams = {
    deviceID: string
}

function MobileLoginScreen({match}: RouteComponentProps<MobileLoginParams>) {
    let cookies = new Cookies();
    cookies.set("device-id", match.params.deviceID, {path: "/"});
    let serverUrl = window.location.host;
    let discordURL = `https://botdesignerdiscord.com/mobile-login/${match.params.deviceID}`;

    setTimeout(() => window.location.href = discordURL, 1000);
    return (
        <div className="App">
            <h1>You are being redirected to login page...</h1>
        </div>
    );
}

interface MobileLoggedScreenProps extends RouteComponentProps<object> {
}

interface MobileLoggedScreenState {
    verified: boolean
    success: boolean
}

class MobileLoggedScreen extends Component<MobileLoggedScreenProps, MobileLoggedScreenState> {

    componentWillMount(): void {
        this.setState({
            verified: false,
            success: false,
        });

        this.verifyCode().then(null);
    }

    async verifyCode(): Promise<void> {
        let cookies = new Cookies();
        let query = this.props.location.search;
        let parsedQuery = parseQuery(query, {});
        let authCode = parsedQuery.code;
        let deviceID = cookies.get("device-id");
        cookies.remove("device-id");
        try {
            let result = await fetch(
                `/api/verify-code?code=${authCode}&device-id=${deviceID}`,
                {method: "POST"}
            );
            if (result.status === 200) {
                let responseJSON = await result.json();
                let tmpSuccess = false;
                if (responseJSON.success) {
                    tmpSuccess = true
                }

                this.setState(
                    {
                        verified: true,
                        success: tmpSuccess,
                    }
                );
                window.close();
                
            } else {
                this.showFailure();
            }
        } catch (e) {
            console.log(e);
            this.showFailure();
        }
    }

    showFailure(): void {
        this.setState(
            {
                verified: true,
                success: false,
            }
        );
    }


    render(): React.ReactNode {
        if (!this.state.verified) {
            return (
                <div className="App">
                    <h1>Verifying few things...</h1>
                </div>
            );
        } else {
            if (this.state.success) {
                return (
                    <div className="App">
                        <h1>Everything Ok</h1>
                        <h5>You can now safely return to app</h5>
                    </div>
                );
            } else {
                return (
                    <div className="App">
                        <h1>Something went wrong</h1>
                        <h5>Please try again in few minutes</h5>
                    </div>
                );
            }
        }
    }
}

export default App;
